import { FC, memo, useState, useCallback, useEffect, useRef } from 'react';
import { Col, Row, Form, AutoComplete, InputNumber, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { Coordinates, Map } from '../Map/Map';

type Props = {
  form: FormInstance;
  onLoading: (value: boolean) => void;
  coordinates: Coordinates;
  setIsFormChanged: (value: boolean) => void;
};

type Option = { value: string; placeId?: string };

const defaultCoordinates = {
  lat: 0,
  lng: 0,
};

const Address: FC<Props> = ({
  form,
  onLoading,
  coordinates,
  setIsFormChanged,
}) => {
  const lat = parseFloat(Form.useWatch('lat', form) ?? '0');
  const lon = parseFloat(Form.useWatch('lon', form) ?? '0');

  const searchRef = useRef<google.maps.places.AutocompleteService | null>(null);
  const placesRef = useRef<google.maps.places.PlacesService | null>(null);

  const { t, i18n } = useTranslation();

  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Option[]>([]);
  const fetchMapAddress = useDebouncedCallback((coordinates: Coordinates) => {
    if (!window.google) return;

    const geocoder = new window.google.maps.Geocoder();
    geocoder
      .geocode({
        language: i18n.language,
        location: coordinates,
      })
      .then((value) => {
        form.setFieldValue('address', value.results[0]?.formatted_address);
        setIsFormChanged(true);
      });
  }, 500);

  useEffect(() => {
    if (lat !== coordinates.lat || lon !== coordinates.lng) {
      fetchMapAddress({ lat, lng: lon });
    }
  }, [lat, lon, fetchMapAddress, coordinates]);

  const handleSearchMap = useDebouncedCallback(
    (value: string) =>
      searchRef.current?.getQueryPredictions({ input: value }, (response) => {
        onLoading(false);
        setAutoCompleteOptions(
          response?.map((item) => ({
            value: item.description,
            placeId: item.place_id,
          })) ?? [],
        );
      }),
    500,
  );

  const handleSelectLocation = useCallback(
    (value: Option) => {
      const place = placesRef.current;

      if (!place || !value.placeId) {
        return;
      }

      onLoading(true);
      place.getDetails({ placeId: value.placeId }, (response) => {
        onLoading(true);
        form.setFieldValue('lat', response?.geometry?.location?.lat());
        form.setFieldValue('lon', response?.geometry?.location?.lng());
        setIsFormChanged(true);
      });
    },
    [form, onLoading, setIsFormChanged],
  );

  return (
    <Col span={12} offset={2}>
      <Row>
        <Form.Item
          name="address"
          label={`${t('locations.labels.address')}:`}
          style={{ width: '100%' }}
        >
          <AutoComplete
            options={autoCompleteOptions}
            placeholder={t('locations.placeholders.enter-address')}
            onChange={handleSearchMap}
            onSelect={(_, option) => handleSelectLocation(option)}
          />
        </Form.Item>
      </Row>

      <Row>
        <Form.Item label={`${t('locations.labels.latitude')}:`} name="lat">
          <InputNumber
            placeholder={t('locations.placeholders.enter-latitude')}
            min={0}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Row>

      <Row>
        <Form.Item label={`${t('locations.labels.longitude')}:`} name="lon">
          <InputNumber
            placeholder={t('locations.placeholders.enter-longitude')}
            min={0}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Row>

      <Row>
        <Map
          initialCoordinates={{
            lat: coordinates.lat ?? defaultCoordinates.lat,
            lng: coordinates.lng ?? defaultCoordinates.lng,
          }}
          markerCoordinates={{
            lat,
            lng: lon,
          }}
          onChangeCoordinates={({ lat, lng }) => {
            form.setFieldValue('lat', lat);
            form.setFieldValue('lon', lng);
            setIsFormChanged(true);
          }}
          onReady={(map) => {
            if (window.google.maps.places.AutocompleteService) {
              const { AutocompleteService, PlacesService } =
                window.google.maps.places;
              searchRef.current = new AutocompleteService();
              placesRef.current = new PlacesService(map);
            }
          }}
        />
      </Row>
    </Col>
  );
};

export default memo(Address);
