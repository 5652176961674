import { Button, Typography } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'features/transactions/types';
import { ConnectorType } from 'features/charge-points/types';
import { ConnectorLabel } from 'components/view/ConnectorLabel';
import type { TableProps } from 'antd/es/table';
import { useAppDispatch } from 'store';
import { setCurrency } from 'features/transactions/transactionsSlice';

export type TransactionsTableColumns = NonNullable<
  TableProps<Transaction>['columns']
>;

export const useTransactionTableColumns = (): TransactionsTableColumns => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return [
    {
      title: t('transactions.columns.transaction-id'),
      dataIndex: 'deviceTransactionId',
      key: 'deviceTransactionId',
      fixed: 'left' as 'left',
      render: (text: string, item: Transaction) => {
        return <Link to={`/transactions/transaction/${item.id}`}>{text}</Link>;
      },
    },
    {
      title: t('transactions.columns.chargepoint-name'),
      dataIndex: 'cpId',
      key: 'cpId',
      render: (cpId: string, record: Transaction) => {
        return (
          <Link to={`/points/${cpId}/details`}>{record.cpName ?? '-'}</Link>
        );
      },
    },
    {
      title: t('transactions.columns.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('transactions.columns.connector-id'),
      dataIndex: 'connectorId',
      key: 'connectorId',
    },
    {
      title: t('transactions.columns.connector-type'),
      dataIndex: 'connectorType',
      render: (type: ConnectorType) => <ConnectorLabel type={type} />,
    },
    {
      title: t('transactions.columns.energy-wh'),
      dataIndex: 'energyWh',
      key: 'energyWh',
    },
    {
      title: t('transactions.columns.amount'),
      dataIndex: 'details',
      key: 'amount',
      render: (details: Transaction['details']) =>
        details.tariff?.totalAmount?.amount,
    },
    {
      title: t('transactions.columns.currency'),
      dataIndex: 'deviceTransactionId',
      key: 'currency',
      width: 100,
      render: (_: string, record: Transaction) =>
        record?.details.tariff?.currency ?? '-',
    },
    {
      title: t('transactions.columns.rfid-card'),
      dataIndex: 'tag',
      key: 'tag',
      render: (tag: string) => (
        <Typography.Paragraph copyable style={{ marginBottom: 0 }}>
          {tag}
        </Typography.Paragraph>
      ),
    },
    {
      title: t('transactions.columns.client-id'),
      dataIndex: 'clientId',
      key: 'clientId',
      render: (_, transaction: Transaction) => {
        const { clientId, clientPhone, clientName } = transaction;

        const renderedValue = clientName
          ? `${clientPhone} (${clientName})`
          : clientPhone;

        return <Link to={`/clients/${clientId}`}>{renderedValue}</Link>;
      },
    },
    {
      title: t('transactions.columns.start-at'),
      dataIndex: 'startAt',
      key: 'startAt',
      render: (startAt: string) =>
        startAt ? dayjs(startAt).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: t('transactions.columns.stop-at'),
      dataIndex: 'stopAt',
      key: 'stopAt',
      render: (stopAt: string) =>
        stopAt ? dayjs(stopAt).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: '',
      width: '50px',
      align: 'center' as 'center',
      dataIndex: 'id',
      key: 'calculations',
      render: (id: string, transaction: Transaction) => {
        return (
          <Link
            to={`/transactions/transaction/${id}/calculations`}
            onClick={() => {
              dispatch(
                setCurrency(transaction?.details?.tariff?.currency ?? ''),
              );
            }}
          >
            <Button type="default">
              {t('transactions.actions.calculation-details')}
            </Button>
          </Link>
        );
      },
    },
  ];
};
